import clsx from "clsx";
import { actionShortcuts } from "../../actions";
import { ActionManager } from "../../actions/manager";
import { t } from "../../i18n";
import { AppState, UIChildrenComponents } from "../../types";
import {
  ExitZenModeAction,
  FinalizeAction,
  UndoRedoActions,
  ZoomActions,
} from "../Actions";
import { useDevice } from "../App";
import { HelpButton } from "../HelpButton";
import { WelcomeScreenHelpArrow } from "../icons";
import { Section } from "../Section";
import Stack from "../Stack";
import WelcomeScreenDecor from "../WelcomeScreenDecor";
import { Button } from "@mui/material";
import { useState } from "react";
import ShareDialog from "../ShareDialog/ShareDialog";
import button_icon from "../../assets/button_icon.png";
import cat_gif from "../../assets/login_cat_gif.gif";
import { toPng } from "html-to-image";
import { getCollaborationLinkData } from "../../excalidraw-app/data";
import CircularProgress from "@mui/material/CircularProgress";

const Footer = ({
  appState,
  actionManager,
  showExitZenModeBtn,
  renderWelcomeScreen,
  footerCenter,
}: {
  appState: AppState;
  actionManager: ActionManager;
  showExitZenModeBtn: boolean;
  renderWelcomeScreen: boolean;
  footerCenter: UIChildrenComponents["FooterCenter"];
}) => {
  const device = useDevice();
  const showFinalize =
    !appState.viewModeEnabled && appState.multiElement && device.isTouchScreen;

  // share popup

  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false); // State for Share dialog
  const [screenshotUrl, setScreenshotUrl] = useState<string | null>(null);
  const [imageFileDetail, setImageFileDetail] = useState<File | null>(null);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false); // State for loading


  const handleShareClick = () => {
    setLoading(true); // Start loading

    const canvas = document.querySelector('canvas'); // Select your canvas element
    if (!canvas) {
      console.error("Canvas element not found.");
      setLoading(false);
      return;
    }

    toPng(canvas)
      .then((dataUrl) => {
        const blob = dataURLToBlob(dataUrl);
        const file = new File([blob], "screenshot.png", { type: "image/png" });
        const url = URL.createObjectURL(file);
        getUserId(); // get user id
        setImageFileDetail(file);
        setScreenshotUrl(url);
        setIsShareDialogOpen(true); // Open dialog
      })
      .catch((err) => console.error("Error:", err))
      .finally(() => setLoading(false)); // Stop loading
  };



  const getUserId = () => {
    // Static URL for testing
    let roomLinkData = getCollaborationLinkData(window.location.href);

    // Create a URL object from the static URL
    const testURL = new URL(
      window.location.href
    );

    // Extract query parameters using URLSearchParams
    const urlParams = new URLSearchParams(testURL.search);

    // Get user_id
    const user_id = urlParams.get("user_id");
    const temp_user_id = user_id ? user_id : null;

    // Log values or use them as needed
    console.log("User ID:", temp_user_id);

    // Example: Reacting to null values
    if (!temp_user_id) {
      console.warn("User ID is missing or null!");
    } else {
      setUserId(temp_user_id)
    }
  }

  // Utility function to convert Data URL to Blob
  const dataURLToBlob = (dataUrl: string) => {
    const byteString = atob(dataUrl.split(",")[1]);
    const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };


  const handleCloseShareDialog = () => {
    setIsShareDialogOpen(false);
    setScreenshotUrl(null); // Reset screenshot state
  };

  // share popup end
  return (
    <footer
      role="contentinfo"
      className="layer-ui__wrapper__footer App-menu App-menu_bottom"
    >

      {/* {footerCenter} */}
      <div
        className={clsx("layer-ui__wrapper__footer-right zen-mode-transition", {
          "transition-right disable-pointerEvents": appState.zenModeEnabled,
        })}
      >
        {/* <div style={{ position: "relative" }}>
          <WelcomeScreenDecor
            shouldRender={renderWelcomeScreen && !appState.isLoading}
          >
            <div className="virgil WelcomeScreen-decor WelcomeScreen-decor--help-pointer">
              <div>{t("welcomeScreen.helpHints")}</div>
              {WelcomeScreenHelpArrow}
            </div>
          </WelcomeScreenDecor>

          <HelpButton
            title={t("helpDialog.title")}
            onClick={() => actionManager.executeAction(actionShortcuts)}
          />
        </div> */}
      </div>
      {/* <ExitZenModeAction
        actionManager={actionManager}
        showExitZenModeBtn={showExitZenModeBtn}
      /> */}
      <div
        className={clsx("layer-ui__wrapper__footer-left zen-mode-transition", {
          "layer-ui__wrapper__footer-left--transition-left":
            appState.zenModeEnabled,
        })}
      >
        <Stack.Col gap={2}>
          <Section heading="canvasActions">

            <button disabled={loading} className="share-button mr-2" onClick={handleShareClick}>
              <img
                alt="Dynamic Cat 1"
                src={cat_gif}
                className="cat_img"
                style={{
                  width: '20px',
                  marginRight: '7px'
                }} />
              {loading ? (
                <CircularProgress size={16} style={{ marginRight: '5px' }} color="inherit" /> // Show loading spinner
              ) : ('')}
              Share

            </button>



            <ZoomActions
              renderAction={actionManager.renderAction}
              zoom={appState.zoom}
            />

            {!appState.viewModeEnabled && (
              <UndoRedoActions
                renderAction={actionManager.renderAction}
                className={clsx("zen-mode-transition", {
                  "layer-ui__wrapper__footer-left--transition-bottom":
                    appState.zenModeEnabled,
                })}
              />
            )}
            {/* {showFinalize && (
              <FinalizeAction
                renderAction={actionManager.renderAction}
                className={clsx("zen-mode-transition", {
                  "layer-ui__wrapper__footer-left--transition-left":
                    appState.zenModeEnabled,
                })}
              />
            )} */}
          </Section>
        </Stack.Col>
      </div>

      {/* Render the Share Dialog */}
      <ShareDialog isOpen={isShareDialogOpen} onClose={handleCloseShareDialog} screenshot={screenshotUrl} imageFileDetail={imageFileDetail} userId={userId} />
    </footer>
  );
};

export default Footer;
Footer.displayName = "Footer";
