import React, { useState } from "react";
import { FormLabel, SelectChangeEvent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import close_icon from "../../assets/close_icon.png";
import white_with_blue_arrow from "../../assets/white_with_blue_arrow.png";
import success_img from "../../assets/success-img.png";
import axios from "axios";
import "./ShareDialog.scss";
import { API_URL } from "../../constants";
import CircularProgress from "@mui/material/CircularProgress";

interface ShareDialogProps {
    isOpen: boolean;
    onClose: () => void;
    screenshot: string | null; // Accept screenshot URL
    imageFileDetail: File | null; // Accept File object
    userId: string | null;
}

const ShareDialog: React.FC<ShareDialogProps> = ({ isOpen, onClose, screenshot, imageFileDetail, userId }) => {
    const [permissionList, setPermissionList] = useState("everyone");
    const [title, setTitle] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission status
    const [loading, setLoading] = useState(false); // State for loading


    const handlePermissionChange = (event: SelectChangeEvent<string>) => {
        setPermissionList(event.target.value);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Ensure required fields are provided
        if (!userId || !imageFileDetail) {
            console.error("User ID or file detail is missing");
            return;
        }

        setLoading(true); // Start loading

        try {
            // Create FormData instance
            const formData = new FormData();
            formData.append("userId", userId);
            formData.append("title", title);
            formData.append("permission", permissionList);
            formData.append("files", imageFileDetail); // Match with multer's expected field name

            // Send POST request
            const response = await axios.post(
                `${API_URL}/wall-post-details/createWallPostAnduploadFiles`,
                formData,
                {
                    headers: {
                        "content-Type": "multipart/form-data",
                    },
                }
            );

            console.log("Wall post created successfully:", response.data);
            setIsSubmitted(true); // Update submission status
        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            setLoading(false); // Stop loading
        }
    };


    const onClosepopup = () => {
        setTitle("");
        setPermissionList("everyone");
        setIsSubmitted(false); // Reset submission status
        onClose();
    };

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: "20px" },
            }}
            open={isOpen}
            onClose={onClosepopup}
        >
            {isSubmitted ? (
                // Success Message
                <div>
                    <DialogTitle>
                        <span className="close_icon" onClick={onClosepopup}>
                            <img src={close_icon} alt="Close" width="30px" />
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        <div className="success-message-for-wall-post">
                            <div>
                                <img src={success_img} alt="Success" width="200px" />
                            </div>

                            <p className="your-screenshort-text">Yay! Your screenshot is now on the Meeku Wall!</p>
                        </div>
                    </DialogContent>

                </div>
            ) : (
                // Form
                <div>
                    <DialogTitle style={{ paddingBottom: "4px" }}>
                        <span className="share-in-wall-title">SHARE IN WALL</span>
                        <p className="user-note">Click the "Share" button to upload your image to the Meeku Wall and share it with your audience!</p>

                        <span className="close_icon" onClick={onClosepopup}>
                            <img src={close_icon} alt="Close" width="30px" />
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        <form className="form-section" onSubmit={handleSubmit}>
                            {/* <p className="user-name-for-popup">Please select your share method</p>
                            <FormControl
                                className="share-inputs"
                                fullWidth
                                variant="outlined"
                                sx={{
                                    width: "135px",
                                    backgroundColor: "#f8f9fa",
                                    borderRadius: "8px",
                                    marginBottom: "13px",
                                }}
                            >
                                <Select
                                    value={permissionList}
                                    onChange={handlePermissionChange}
                                    sx={{
                                        height: "27px",
                                        fontSize: "12px",
                                        color: "#06110AB2",
                                        fontFamily: "Poppins",
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#4011C6",
                                        },
                                        ".MuiSvgIcon-root": {
                                            color: "#007bff",
                                        },
                                    }}
                                >
                                    <MenuItem sx={{ fontSize: "12px", fontFamily: "Poppins", }} value="only_me">Only Me</MenuItem>
                                    <MenuItem sx={{ fontSize: "12px", fontFamily: "Poppins", }} value="only_friends">Only Friends</MenuItem>
                                    <MenuItem sx={{ fontSize: "12px", fontFamily: "Poppins", }} value="everyone">Everyone</MenuItem>
                                </Select>
                            </FormControl> */}
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormLabel className="enter-title-text">Enter title</FormLabel>
                                    <TextField
                                        className="share-inputs"
                                        placeholder="Give your image a cool title!"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        sx={{
                                            "& .MuiOutlinedInput-root": { fontSize: "13px", fontFamily: "Poppins" },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {screenshot ? (
                                        <div className="screenshot-preview-div">
                                            <img
                                                src={screenshot}
                                                alt="Screenshot Preview"
                                                style={{ width: "100%", borderRadius: "8px" }}
                                            />
                                        </div>
                                    ) : (
                                        <p>Taking screenshot...</p>
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                        <div className="share-popup-footer-div">
                            <button onClick={onClosepopup} className="cancel-button-for-popup">
                                Cancel
                            </button>
                            <button disabled={loading} onClick={handleSubmit} className="share-button-for-popup">
                                {loading ? (
                                    <CircularProgress size={16} style={{ marginRight: '10px' }} color="inherit" /> // Show loading spinner
                                ) : ('')}     Share
                                <img src={white_with_blue_arrow} width="20px" alt="Share" className="ml-2" />
                            </button>
                        </div>
                    </DialogContent>

                </div>
            )}
        </Dialog>

    );
};

export default ShareDialog;
