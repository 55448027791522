import {
  useRef,
  useState,
  useEffect,
  useCallback,
  RefObject,
  forwardRef,
} from "react";
import axios from 'axios';
import Library, {
  distributeLibraryItemsOnSquareGrid,
  libraryItemsAtom,
} from "../data/library";
import { t } from "../i18n";
import { randomId } from "../random";
import { LibraryItems, LibraryItem, AppState, ExcalidrawProps } from "../types";

import "./LibraryMenu.scss";
import LibraryMenuItems from "./LibraryMenuItems";
import { API_URL, EVENT } from "../constants";
import { KEYS } from "../keys";
import { trackEvent } from "../analytics";
import { useAtom } from "jotai";
import { jotaiScope } from "../jotai";
import Spinner from "./Spinner";
import {
  useDevice,
  useExcalidrawElements,
  useExcalidrawSetAppState,
} from "./App";
import { Sidebar } from "./Sidebar/Sidebar";
import { getSelectedElements } from "../scene";
import { NonDeletedExcalidrawElement } from "../element/types";
import { LibraryMenuHeader } from "./LibraryMenuHeaderContent";
import LibraryMenuBrowseButton from "./LibraryMenuBrowseButton";
import { getCollaborationLink, getCollaborationLinkData } from "../excalidraw-app/data";

const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  cb: (event: MouseEvent) => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current) {
        return;
      }

      if (
        event.target instanceof Element &&
        (ref.current.contains(event.target) ||
          !document.body.contains(event.target))
      ) {
        return;
      }

      cb(event);
    };
    document.addEventListener("pointerdown", listener, false);

    return () => {
      document.removeEventListener("pointerdown", listener);
    };
  }, [ref, cb]);
};

const LibraryMenuWrapper = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>(({ children }, ref) => {
  return (
    <div ref={ref} className="layer-ui__library">
      {children}
    </div>
  );
});

export const LibraryMenuContent = ({
  onInsertLibraryItems,
  pendingElements,
  onAddToLibrary,
  setAppState,
  libraryReturnUrl,
  library,
  id,
  appState,
  selectedItems,
  onSelectItems,
  onLibraryImageAction,
  libraryImagesList
}: {
  pendingElements: LibraryItem["elements"];
  onInsertLibraryItems: (libraryItems: LibraryItems) => void;
  onAddToLibrary: () => void;
  setAppState: React.Component<any, AppState>["setState"];
  libraryReturnUrl: ExcalidrawProps["libraryReturnUrl"];
  libraryImagesList: any[];
  library: Library;
  id: string;
  appState: AppState;
  selectedItems: LibraryItem["id"][];
  onSelectItems: (id: LibraryItem["id"][]) => void;
  onLibraryImageAction: (data: { insertOnCanvasDirectly: boolean, imgUrl: string }) => void;
}) => {
  const [libraryItemsData] = useAtom(libraryItemsAtom, jotaiScope);

  const addToLibrary = useCallback(
    async (elements: LibraryItem["elements"], libraryItems: LibraryItems) => {
      trackEvent("element", "addToLibrary", "ui");
      // if (elements.some((element) => element.type === "image")) {
      //   return setAppState({
      //     errorMessage: "Support for adding images to the library coming soon!!!",
      //   });
      // }
      const nextItems: LibraryItems = [
        {
          status: "unpublished",
          elements,
          id: randomId(),
          created: Date.now(),
        },
        ...libraryItems,
      ];
      onAddToLibrary();
      library.setLibrary(nextItems).catch(() => {
        setAppState({ errorMessage: t("alerts.errorAddingToLibrary") });
      });
    },
    [onAddToLibrary, library, setAppState],
  );

  if (
    libraryItemsData.status === "loading" &&
    !libraryItemsData.isInitialized
  ) {
    return (
      <LibraryMenuWrapper>
        <div className="layer-ui__library-message">
          <div>
            <Spinner size="2em" />
            <span>{t("labels.libraryLoadingMessage")}</span>
          </div>
        </div>
      </LibraryMenuWrapper>
    );
  }

  const showBtn =
    libraryItemsData.libraryItems.length > 0 || pendingElements.length > 0;

  return (
    <LibraryMenuWrapper>
      <LibraryMenuItems
        isLoading={libraryItemsData.status === "loading"}
        libraryItems={libraryItemsData.libraryItems}
        libraryImagesList={libraryImagesList}
        onAddToLibrary={(elements) =>
          addToLibrary(elements, libraryItemsData.libraryItems)
        }
        onInsertLibraryItems={onInsertLibraryItems}
        pendingElements={pendingElements}
        selectedItems={selectedItems}
        onSelectItems={onSelectItems}
        id={id}
        libraryReturnUrl={libraryReturnUrl}
        onLibraryImageAction={onLibraryImageAction}
        theme={appState.theme}
      />
      {/* {showBtn && (
        <LibraryMenuBrowseButton
          id={id}
          libraryReturnUrl={libraryReturnUrl}
          theme={appState.theme}
        />
      )} */}
    </LibraryMenuWrapper>
  );
};

export const LibraryMenu: React.FC<{
  appState: AppState;
  onInsertElements: (elements: readonly NonDeletedExcalidrawElement[]) => void;
  libraryReturnUrl: ExcalidrawProps["libraryReturnUrl"];
  focusContainer: () => void;
  library: Library;
  onLibraryImageAction: (data: { insertOnCanvasDirectly: boolean, imgUrl: string }) => void;
  id: string;
}> = ({
  appState,
  onInsertElements,
  libraryReturnUrl,
  focusContainer,
  library,
  onLibraryImageAction,
  id,
}) => {
    const setAppState = useExcalidrawSetAppState();
    const elements = useExcalidrawElements();
    const device = useDevice();

    const [selectedItems, setSelectedItems] = useState<LibraryItem["id"][]>([]);
    const [libraryItemsData] = useAtom(libraryItemsAtom, jotaiScope);

    const [libraryImagesList, setLibraryImagesList] = useState([]);

    const ref = useRef<HTMLDivElement | null>(null);

    const closeLibrary = useCallback(() => {
      const isDialogOpen = !!document.querySelector(".Dialog");

      // Prevent closing if any dialog is open
      if (isDialogOpen) {
        return;
      }
      setAppState({ openSidebar: null });
    }, [setAppState]);

    useOnClickOutside(
      ref,
      useCallback(
        (event) => {
          // If click on the library icon, do nothing so that LibraryButton
          // can toggle library menu
          if ((event.target as Element).closest(".ToolIcon__library")) {
            return;
          }
          if (!appState.isSidebarDocked || !device.canDeviceFitSidebar) {
            closeLibrary();
          }
        },
        [closeLibrary, appState.isSidebarDocked, device.canDeviceFitSidebar],
      ),
    );

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (
          event.key === KEYS.ESCAPE &&
          (!appState.isSidebarDocked || !device.canDeviceFitSidebar)
        ) {
          closeLibrary();
        }
      };
      let roomLinkData = getCollaborationLinkData(window.location.href);
      var urlParams = new URLSearchParams(window.location.search);
      var self_learning_board_id = urlParams.get("self_learning_board_id");
      var temp_self_learning_board_id = self_learning_board_id ? self_learning_board_id : null;

      if (roomLinkData) {
        console.log('roomLinkData', roomLinkData)
        let splitRoomId = roomLinkData?.roomId.split('-div-');
        if (splitRoomId.length == 4) {
          getLibraryDocsByContentId(splitRoomId[1]);
        } else if (temp_self_learning_board_id) {
          getBySelfLearningBoardId(temp_self_learning_board_id);
        }
        else {
          getLibraryDocs();
        }
      } else {
        getLibraryDocs();
      }
      document.addEventListener(EVENT.KEYDOWN, handleKeyDown);
      return () => {
        document.removeEventListener(EVENT.KEYDOWN, handleKeyDown);
      };
    }, [closeLibrary, appState.isSidebarDocked, device.canDeviceFitSidebar]);

    const getLibraryDocsByContentId = (id: any) => {
      axios.get(API_URL + `/image-libraries/getByBoardContentId/` + id)
        .then(res => {
          if (res.data.data) {
            var docList = res.data.data.filter((e: any) => e.status == 1);
            setLibraryImagesList(docList);
          }
        }).catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
    }

    const getBySelfLearningBoardId = (id: any) => {
      axios.get(API_URL + `/image-libraries/getBySelfLearningBoardId/` + id)
        .then(res => {
          if (res.data.data) {
            var docList = res.data.data.filter((e: any) => e.status == 1);
            setLibraryImagesList(docList);
          }
        }).catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
    }

    const getLibraryDocs = () => {
      axios.get(API_URL + `/image-libraries`)
        .then(res => {
          if (res.data.data) {
            var docList = res.data.data.filter((e: any) => e.status == 1);
            console.log(docList);
            setLibraryImagesList(docList);
          }
        }).catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
    }

    const deselectItems = useCallback(() => {
      setAppState({
        selectedElementIds: {},
        selectedGroupIds: {},
      });
    }, [setAppState]);

    const removeFromLibrary = useCallback(
      async (libraryItems: LibraryItems) => {
        const nextItems = libraryItems.filter(
          (item) => !selectedItems.includes(item.id),
        );
        library.setLibrary(nextItems).catch(() => {
          setAppState({ errorMessage: t("alerts.errorRemovingFromLibrary") });
        });
        setSelectedItems([]);
      },
      [library, setAppState, selectedItems, setSelectedItems],
    );

    const resetLibrary = useCallback(() => {
      library.resetLibrary();
      focusContainer();
    }, [library, focusContainer]);

    return (
      <Sidebar
        __isInternal
        // necessary to remount when switching between internal
        // and custom (host app) sidebar, so that the `props.onClose`
        // is colled correctly
        key="library"
        className="layer-ui__library-sidebar"
        initialDockedState={appState.isSidebarDocked}
        onDock={(docked) => {
          trackEvent(
            "library",
            `toggleLibraryDock (${docked ? "dock" : "undock"})`,
            `sidebar (${device.isMobile ? "mobile" : "desktop"})`,
          );
        }}
        ref={ref}
      >
        <Sidebar.Header className="layer-ui__library-header">
          <LibraryMenuHeader
            appState={appState}
            setAppState={setAppState}
            selectedItems={selectedItems}
            onSelectItems={setSelectedItems}
            library={library}
            onRemoveFromLibrary={() =>
              removeFromLibrary(libraryItemsData.libraryItems)
            }
            resetLibrary={resetLibrary}
          />
        </Sidebar.Header>
        <LibraryMenuContent
          pendingElements={getSelectedElements(elements, appState, true)}
          onInsertLibraryItems={(libraryItems) => {
            onInsertElements(distributeLibraryItemsOnSquareGrid(libraryItems));
          }}
          onAddToLibrary={deselectItems}
          setAppState={setAppState}
          libraryReturnUrl={libraryReturnUrl}
          libraryImagesList={libraryImagesList}
          library={library}
          id={id}
          appState={appState}
          selectedItems={selectedItems}
          onSelectItems={setSelectedItems}
          onLibraryImageAction={onLibraryImageAction}
        />
      </Sidebar>
    );
  };
